<style lang="sass" scoped>

</style>
<template lang="pug">
div.bg-light
  //- form.form(@submit.prevent='submit')
  .container-lg
    .bg-white.shadow-sm.p-3(style='font-size: 12px')
      h1 criteria
      pre orderids {{orderids}}
      pre template_id {{template_id}} {{template}}
      strong 발신자선택
      b-form-radio-group(v-model='sender_selected' :options='sender_options')
      hr
      ul
        li
          p
            strong.bg-light.p-2 {{orderids.length}}명
            | 수신자에게

        li
          p(v-show='sms_text')
            | 문자 메시지
            strong.bg-light.p-2 {{sms_text}}
            | 를 보냅니다.
          p.text-muted(v-show='!sms_text') 문자는 보내지 않습니다.
        li
          p
            | 발신자 번호는
            strong.bg-light.p-2 {{sender_selected}}
            | 입니다.
        li(v-if='template')
          p(v-show='email_subject')
            strong.bg-light.p-2 {{email_subject}}
            | 이메일을 보냅니다.
          p.text-muted(v-show='!email_subject') 이메일은 보내지 않습니다.
      //- p 상태랑 태그
      //- strong 상태
      //- b-form-radio-group(v-model='status_selected' :options='status_options')
      //- hr
      //- label 태그
      //- b-form-checkbox-group(v-model='tag_selected' :options='tag_options')
      //- b-form-checkbox(v-model='exclude_template_resend' value='Y' unchecked-value='N')
      //-   | 템플릿 중복체크 (재발송하지 않음)

      h1 target audience
      p 나열
      //- pre {{targets}}
      table.table.table-condensed
        thead
          tr
            th 번호
            th 이름
              span.text-muted.float-right {{targets.length}}건
            th 문자전송체크
              span.text-muted.float-right {{count_phone}}건
            th 이메일전송체크
              span.text-muted.float-right {{count_email}}건
        tbody
          tr(v-for='(row, $index) in targets')
            td(v-text='$index+1')
            td
              b-form-checkbox(v-model='row.checked' value='Y' unchecked-value='N') {{row.name}}
            td(:class="{'table-warning text-muted':(row.phone_error),'text-success':(!row.phone_error)}")
              strong(v-text='({Y:"가능", N:"확인필요"})[row.phone_indexed]')
              strong.float-right(v-if='row.phone_error') {{row.phone_error}}
            td(:class="{'table-warning text-muted':(row.email_error),'text-success':(!row.email_error)}")
              strong(v-text='({Y:"가능", N:"확인필요"})[row.email_indexed]')
              strong.float-right(v-if='row.email_error') {{row.email_error}}

      div(v-if='!template_id')
        h6 수기입력폼
        //- pre MessageForm
        h3 template_id {{template_id}}
        label 문자
        textarea.form-control(rows=5 v-model='sms_text')
        label 이메일제목
        textarea.form-control(rows=5 v-model='email_subject')
        label 이메일내용
        textarea.form-control(rows=5 v-model='email_body')


      h6 완료

      b-form-checkbox(v-model='paused_at' value='Y' unchecked-value='N')
        | 예약만 해놓고 나중에 버튼클릭으로 보냅니다. (즉시보내려면 체크해제)
      button.btn.btn-primary(type='button' @click='submit') 보내기

      .alert.bg-light(v-if='sms_config') 일일발송한도:
        span(v-for='n in sms_config.numbers') ({{n.number}}, {{n.quota_daily}} messages per a day)
        a(href='#' onClick='alert("create ticket"); return false') 한도추가요청

    //- .col

      .bg-white.p-2.mt-2.border(style='font-size: 12px')

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'sf_id', 'order_id'],
  components: {

  },
  computed: {

  },
  watch: {
    '$store.state.tags' (after) {
      console.log('$store.state.tags')
      this.load_options()
    },
  },
  data() {
    return {
      sender_selected: '',
      sender_options: [],
      status_selected: '',
      status_options: [],
      tag_selected: [],
      tag_options: [],

      orderids: [],
      template_id: 0,
      template: {},
      exclude_template_resend: 'N',
      paused_at: 'Y',

      targets: [],
      count_phone: '',
      count_email: '',

      sms_text: '',
      email_subject: '',
      email_body: '',

      form: {
        sms_sender_phone: '',
        sms_result: '',
        sms_input: '',
      },
      sms_active: false,
      sms_config: {},
    }
  },
  async mounted() {
    this.fetch_sms_config()
    // this.load_options()
    this.load_orderids()
    this.fetch_targets()
  },
  methods: {
    load_orderids() {
      if (this.$route.query.oids) {
        this.orderids = JSON.parse(this.$route.query.oids)
      }
      if (this.$route.query.template_id) {
        this.template_id = this.$route.query.template_id
      }
    },
    load_options() {
      this.status_options = ['전체'].concat(this.$store.state.status_flows).map(e => {
        return {
          text: e,
          value: e,
        }
      })
      this.tag_options = this.$store.state.tags.map(e => {
        return {
          text: e,
          value: e,
        }
      })

      setTimeout(() => {
        this.template = (this.$store.state.message_templates.filter(e => e.id == this.template_id) || [{}])[0]
        if (this.template) {
          this.sms_text = this.template.sms_text
          this.email_subject = this.template.email_subject
          this.email_body = this.template.email_body
        }
      }, 300);
    },
    async fetch_sms_config() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/sms_config`)
        this.sms_config = r.data.sms_config
        if (!this.sms_config.numbers) {
          this.sms_config.numbers = []
        } else {
          this.sender_selected = this.sms_config.numbers[0].number
        }
        this.sender_options = this.sms_config.numbers.map(e => e.number)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: 'failed to load: sms config',
        })
      }
    },
    async fetch_targets() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/message-groups/actions/test-targets`, {
          order_ids: this.orderids,
          // exclude_message_template_id:
        })
        this.targets = r.data.rows.map(e => {
          console.log(e)
          if (e.phone_error && e.email_error) {
            e.checked = 'N'
          } else {
            e.checked = 'Y'
          }
          return e
        })
        this.count_phone = r.data.count_phone
        this.count_email = r.data.count_email

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: 'failed to load: targets',
        })
      }
    },
    async submit() {
      try {
        const r = await this.$http.post(`/v1/property/${this.property_id}/views/message-groups`, {
          target: {
            order_ids: this.targets.filter(e => e.checked == 'Y').map(e => e.id),
          },
          sms_text: this.sms_text,
          email_subject: this.email_subject,
          email_body: this.email_body,
          paused_at: this.paused_at,
          message_template_id: this.message_template_id,
          sender_phone: this.sender_selected,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        // this.fetch_order()
        // this.fetch_order_log()
        alert('saved')
        this.$router.push({
          path: `/property/${this.property_id}/views/message/sent`
        })
      } catch (error) {
        console.log(error.stack)
      }
    },

    sms_fill(template) {
      console.log(33, template)
      // this.form.sms_input = 'sddsf'
      // this.$set(this.form, 'sms_input', this.form.sms_input + template.text)
    },

    sms_toggle() {
      this.sms_active = !this.sms_active
    },

    send_sms_template(template) {
      console.log(template)
      this.form.sms_input = template.text
      this.$nextTick(() => {
        this.sms_result()
        this.$nextTick(() => {
          this.send_sms()
        })
      })
    },

    async send_sms() {
      try {
        // this.$modal.show('order-sms-review')
        const text = `발신자: ${this.form.sms_sender_phone}\n수신자: ${this.order.phone}\n\n${this.form.sms_result}\n\n 발송하시겠습니까?`
        if(!confirm(text)) return false
        const r = await this.$http.post(`/v1/property/${this.property_id}/orders/${this.order.id}/send_sms`, {
          sender_phone: this.form.sms_sender_phone,
          text: this.form.sms_result,
        })
        if (r?.data?.message != 'ok') throw new Error('문자발송 실패')

        this.form.sms_input = ''
        this.form.sms_result = ''

        this.fetch_order_history()

        this.$modal.show('dialog', {
          title: '알림',
          text: '문자를 보냈습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    sms_result() {
      let text = '' + this.form.sms_input
      text = text.replace('@고객명', this.order.name)

      this.$nextTick(() => {
        this.form.sms_result = text
      })
    },

    form_touched() {
      if (this.has_changed()) {
        window.FormTouched = 'Y'
      } else {
        window.FormTouched = 'N'
      }
      console.log(window.FormTouched)
    },

  },
}
</script>
