import { render, staticRenderFns } from "./CustomerViewMessage.vue?vue&type=template&id=4adce000&scoped=true&lang=pug&"
import script from "./CustomerViewMessage.vue?vue&type=script&lang=js&"
export * from "./CustomerViewMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adce000",
  null
  
)

export default component.exports